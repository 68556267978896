* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  position: relative;
}

@mixin centerChilds {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider_containers {
  position: relative;
  height: 100dvh;
  overflow: hidden;
  @include centerChilds;
  background: black;
  .p-link {
    position: absolute;
    visibility: hidden;
    &.p-carousel-prev {
      left: 0;
    }
    &.p-carousel-next {
      right: 0;
    }

    svg {
      height: 30px;
      width: 30px;
      color: white;
    }
  }
  .p-carousel-item {
    width: 100%;
    position: relative;
    .video-slide {
      width: 100%;

      .next,
      .back {
        position: absolute;
        z-index: 10;
        padding: 10px;
        @include centerChilds;
        img {
          height: 50px;
          width: 40px;
          cursor: pointer;
        }
      }
      .back {
        inset: 0 auto 10vh 0;
      }
      .next {
        inset: 0 0 10vh auto;
      }

      video {
        width: 100%;
        height: 100dvh;
      }
    }
  }
}
